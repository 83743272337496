import './footpartners.scss';

import kaspersky from '../../.././assets/img/partners-logos/kaspersky.svg';
import odinc from '../../.././assets/img/partners-logos/odinc.svg';
import kasperskyDark from '../../.././assets/img/partners-logos/kaspersky-dark.svg';
import odincDark from '../../.././assets/img/partners-logos/odinc-dark.svg';

const footpartners = [
  {
    image: kaspersky,
    imageDark: kasperskyDark,
    text: 'Kaspersky',
    textEng: 'Kaspersky',
  },
  {
    image: odinc,
    imageDark: odincDark,
    text: '1C',
    textEng: '1C',
  }
];

const Footpartners = ({ isLight, lang }) => {
  return (
    <section className='footpartners-section'>
      <ul className='footpartners-section_footpartners-list'>
        {footpartners.map((item) => {
          return (
            <li
              className='footpartners-section_footpartners-item footpartners-item'
              key={footpartners.indexOf(item)}
            >
              <div
                className={
                  isLight
                    ? 'footpartners-item_image-wrapper'
                    : 'footpartners-item_image-wrapper--dark'
                }
              >
                <img
                  className='footpartners-item_image'
                  src={isLight ? item.image : item.imageDark}
                  alt={lang === 'ru' ? item.text : item.textEng}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Footpartners;


import mobileMenuButton from '../../assets/img/mobile-menu-button-black.svg';
import mobileMenuButtonWhite from '../../assets/img/mobile-menu-button-white.svg';
import closeButton from '../../assets/img/close-button.svg';
import './header.scss';



const   Header = ({
                      logoBlack,
                      logoWhite,
                      isLight,
                      turnThemeLight,
                      turnThemeDark,
                      lang,
                      setRu,
                      setEng,
                      setIsNavOpened,
                      isNavOpened,
                  }) => {
    const openHandler = () => {
        setIsNavOpened(true);
    };

    const closeHandler = () => {
        setIsNavOpened(false);
    };

    const ruClassNameHandler = () => {
        if (isLight && lang === 'ru') {
            return 'lang-toggler--current';
        } else if (!isLight && lang === 'ru') {
            return 'lang-toggler--current--dark';
        }

        return '';
    };

    const engClassNameHandler = () => {
        if (isLight && lang === 'eng') {
            return 'lang-toggler--current';
        } else if (!isLight && lang === 'eng') {
            return 'lang-toggler--current--dark';
        }

        return '';
    };

    const func = () => {
        const theme = isLight
            ? 'header_control-panel'
            : 'header_control-panel--dark';
        const opened = isNavOpened ? 'header_control-panel--opened' : '';

        return `${theme} ${opened}`;
    };

    return (
        <header className='header'>
            <img
                className='header_logo'
                src={isLight ? logoBlack : logoWhite}
                alt='IMI'
            />
            <div className={func()}>
                <img
                    className='header_logo menu-logo'
                    src={isLight ? logoBlack : logoWhite}
                    alt='IMI'
                />
                <div className='header_theme-toggler theme-toggler'>
                    <label>
                        <input
                            type='radio'
                            name='theme_header'
                            defaultChecked={isLight ? true : false}
                            onChange={turnThemeLight}
                        />
                        <span className={isLight ? 'theme-toggler--current' : ''}>
              Light
            </span>
                    </label>
                    <label>
                        <input
                            type='radio'
                            name='theme_header'
                            onChange={turnThemeDark}
                            defaultChecked={isLight ? false : true}
                        />
                        <span className={isLight ? '' : 'theme-toggler--current--dark'}>
              Dark
            </span>
                    </label>
                </div>
                <div className='header_container'>
                    <div className='header_lang-toggler lang-toggler'>
                        <label>
                            <input
                                type='radio'
                                name='lang'
                                defaultChecked={lang === 'ru' ? true : false}
                                onChange={setRu}
                            />
                            <span className={ruClassNameHandler()}>Рус</span>
                        </label>
                        <label>
                            <input
                                type='radio'
                                name='lang'
                                onChange={setEng}
                                defaultChecked={lang !== 'ru' ? true : false}
                            />
                            <span className={engClassNameHandler()}>Eng</span>
                        </label>
                    </div>
                    <div className='header_phone-wrapper'>
                        <a
                            className={isLight ? 'header_phone' : 'header_phone--dark'}
                            href='tel:+73422596896'
                        >
                            +7 (342) 259-68-96
                        </a>
                    </div>
                    <div className='contact-us-button-wrapper'>
                        <a
                            className={
                                isLight ? 'contact-us-button' : 'contact-us-button--dark'
                            }
                            href='#form-section'
                        >
                            {lang === 'ru' ? 'Написать нам' : 'Send a message'}
                        </a>
                    </div>
                </div>
                <button className='close-button' onClick={closeHandler}>
                    <img src={closeButton} alt='' />
                </button>
            </div>
            <img
                className='header_mobile-menu-button'
                src={isLight ? mobileMenuButton : mobileMenuButtonWhite}
                onClick={openHandler}
                alt=''
            />
        </header>
    );
};

export default Header;

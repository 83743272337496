import './agreement.scss';

import closeButton from '../../assets/img/close.svg';
import closeButtonWhite from '../../assets/img/close-white.svg';
import AgreementTextRu from './agreement-text-ru.jsx';
import AgreementTextEng from './agreement-text-eng.jsx';
import AgreementTitle from './agreement-title';

const Agreement = ({
  agreementCloseHandler,
  isAgreementOpened,
  isLight,
  lang,
}) => {
  return (
    <div
      className={
        isAgreementOpened ? 'agreement_bg agreement_bg--opened' : 'agreement_bg'
      }
    >
      <div
        className={
          isLight
            ? 'agreement_container'
            : 'agreement_container agreement_container--dark'
        }
      >
        <div className='agreement_close-button' onClick={agreementCloseHandler}>
          <img src={isLight ? closeButton : closeButtonWhite} alt=''/>
        </div>
        <AgreementTitle lang={lang} />
        {lang === 'ru' ? <AgreementTextRu /> : <AgreementTextEng />}
      </div>
    </div>
  );
};

export default Agreement;

const AgreementTextEng = () => {
  return (
    <div className='agreement_text-container'>
      <p>
        I hereby accept the terms and conditions of investmarketing.ru,
        including the terms of processing personal data, sale of services and
        goods, as well as acceptance of payments in accordance with the
        legislation of the Russian Federation and the agreement concluded with
        me if any. Data processing is performed by the following IMI group
        companies:
      </p>
      <p className='agreement_bold'>Investment Marketing Institute LLC</p>
      <p>
        TIN 5906078125
        <br />
        RRC 590601001
        <br />
        REGISTRY NUMBER 1075906007385
      </p>
      <p className='agreement_bold'>IMI Media LLC</p>
      <p>
        TIN 5906105146
        <br />
        RRC 590601001
        <br />
        REGISTRY NUMBER 1115906000352
      </p>
      <p>
        Herewith in accordance with the Federal Law No 152 "On Personal Data"
        dated 27.07.2006 I give my unconditional consent to the processing of my
        personal data by the IMI Group of companies: "Investment Marketing
        Institute" LLC, “IMI Media" LLC (hereinafter - the Operator).
      </p>
      <p>
        Personal Data - any information relating to an individual defined or
        identifiable on the basis of such information. This Consent is given by
        me to process the following personal data:
      </p>
      <ul className='agreement_data-list'>
        <li className='agreement_data-item'>Surname, first name, patronymic</li>
        <li className='agreement_data-item'>Company name</li>
        <li className='agreement_data-item'>Telephone number</li>
        <li className='agreement_data-item'>E-mail address</li>
        <li className='agreement_data-item'>Comments on forms</li>
        <li className='agreement_data-item'>Other data</li>
      </ul>
      <p>
        The consent is given to the Operator for performing the following
        actions with my personal data with use of automation and/or without the
        use of such means: collection, systematization, accumulation, storage,
        clarification (updating, change), use, depersonalization, transfer to
        third parties for the purposes specified below, as well as performing
        any other actions stipulated by the current legislation of the Russian
        Federation both by non-automated and automated means.
      </p>
      This consent is given to the Operator for the processing of my personal
      data for the following purposes:
      <br />
      <ul className='agreement_aim-list'>
        <li>providing services/work to me;</li>
        <li>notifications regarding the services / works provided;</li>
        <li>preparing and sending replies to my queries;</li>
        <li>
          sending me information, including advertising, about the Operator's
          events/goods/services/work.
        </li>
      </ul>
      <p>
        This consent shall be valid until revoked by sending a relevant notice
        to the email address info@investmarketing.ru. If I withdraw my consent
        to processing personal data, the Operator shall be entitled to continue
        processing my personal data without my consent for the reasons specified
        in clauses 2 - 11 of part 1 of Article 6, part 2 of Article 10 and part
        2 of Article 11 of Federal Law No 152 "On Personal Data" dated
        26.06.2006.
      </p>
    </div>
  );
};

export default AgreementTextEng;

import './footer.scss';

import Footpartners from './footpartners/footpartners';

const footerNav = [
  { ru: 'Группа компаний IMI', eng: 'IMI Group of companies' },
  { ru: 'IMI MANAGEMENT', eng: 'IMI MANAGEMENT' },
  { ru: 'IMI CONSULTING & RESEARCH', eng: 'IMI CONSULTING & RESEARCH' },
  { ru: 'IMI CONFERENCE', eng: 'IMI CONFERENCE' },
  { ru: 'IMI MEDIA', eng: 'IMI MEDIA' },
  { ru: 'IMI MARKETING & DESIGN', eng: 'IMI MARKETING & DESIGN' },
  { ru: 'IMI ENGINEERING', eng: 'IMI ENGINEERING' },
];

const Footer = ({
  logoBlack,
  logoWhite,
  isLight,
  turnThemeLight,
  turnThemeDark,
  lang,
  agreementOpenHandler,
}) => {
  return (
    <footer className='footer'>
      <div className='top-row'>
        <div className='top-row_column-1'>
          <div className='footer_logo-wrapper'>
            <img
              className='footer_logo'
              src={isLight ? logoBlack : logoWhite}
              alt=''
            />
          </div>
          <div className='footer_address-wrapper'>
            <p className='footer_address'>
              {lang === 'ru'
                ? '614000, г. Пермь, \n ул. Осинская, д. 8'
                : '614000, Russia, Perm,\n8 Osinskaya Str. '}
            </p>
          </div>
          <Footpartners isLight={isLight} lang={lang} />
        </div>
        <div className='top-row_column-2'>
          <div className='footer_nav-list-wrapper'>
            <ul className='footer_nav-list'>
              {footerNav.map((item) => (
                <li key={item.eng}>{lang === 'ru' ? item.ru : item.eng}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className='top-row_column-3'>
          <div className='footer_phone-number-wrapper'>
            <p className='footer_phone-number'>+7 (342) 259-68-96</p>
          </div>
          <div className='footer_email-wrapper'>
            <p className={isLight ? 'footer_email' : 'footer_email--dark'}>
              {lang === 'ru' ? 'Пишите нам\n' : 'Send a message to\n'}{' '}
              <a href='mailto:info@investmarketing.ru'>
                info@investmarketing.ru
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className='bottom-row'>
        <div className='footer_theme-toggler theme-toggler'>
          <label>
            <input
              type='radio'
              name='theme_header'
              defaultChecked={true}
              onChange={turnThemeLight}
            />
            <span className={isLight ? 'theme-toggler--current' : ''}>
              Light
            </span>
          </label>
          <label>
            <input type='radio' name='theme_header' onChange={turnThemeDark} />
            <span className={isLight ? '' : 'theme-toggler--current--dark'}>
              Dark
            </span>
          </label>
        </div>
        <div className='footer_agreement-wrapper'>
          <a
            className={isLight ? 'footer_agreement' : 'footer_agreement--dark'}
            onClick={agreementOpenHandler}
          >
            {lang === 'ru' ? 'Пользовательское соглашение' : 'User agreement'}
          </a>
        </div>
        <div className='footer_company-info-wrapper'>
          <p className='footer_company-info'>
            {lang === 'ru'
              ? '© Группа компаний IMI 2006-2022'
              : '© IMI Group of companies 2006-2022'}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const AgreementTextRu = () => {
  return (
    <div className='agreement_text-container'>
      <p>
        Настоящим я принимаю условия работы сайта investmarketing.ru, в том
        числе условия обработки персональных данных, продажи услуг и товаров, а
        также приема платежей в соответствии с законодательством РФ, а также с
        заключенным со мной договором. Компании группы IMI, обрабатывающие
        данные:
      </p>
      <p className='agreement_bold'>
        ООО «Институт инвестиционного маркетинга»
      </p>
      <p>
        ИНН 5906078125
        <br />
        КПП 590601001
        <br />
        ОГРН 1075906007385
      </p>
      <p className='agreement_bold'>ООО «Ай-Эм-Ай Медиа»</p>
      <p>
        ИНН 5906105146
        <br />
        КПП 590601001 ОГРН
        <br />
        1115906000352
      </p>
      <p>
        Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных
        данных» от 27.07.2006 года свободно, своей волей и в своем интересе
        выражаю свое безусловное согласие на обработку моих персональных данных
        предприятиями группы компаний IMI: ООО «Институт инвестиционного
        маркетинга», ООО «Ай-Эм-Ай Медиа» (далее по тексту - Оператор).
      </p>
      <p>
        Персональные данные - любая информация, относящаяся к определенному или
        определяемому на основании такой информации физическому лицу. Настоящее
        Согласие выдано мною на обработку следующих персональных данных:
      </p>
      <ul className='agreement_data-list'>
        <li className='agreement_data-item'>Фамилия, Имя, Отчество</li>
        <li className='agreement_data-item'>Название компании</li>
        <li className='agreement_data-item'>Телефонный номер</li>
        <li className='agreement_data-item'>Электронный адрес</li>
        <li className='agreement_data-item'>Комментарии в формах</li>
        <li className='agreement_data-item'>Другие данные</li>
      </ul>
      <p>
        Согласие дано Оператору для совершения следующих действий с моими
        персональными данными с использованием средств автоматизации и/или без
        использования таких средств: сбор, систематизация, накопление, хранение,
        уточнение (обновление, изменение), использование, обезличивание,
        передача третьим лицам для указанных ниже целей, а также осуществление
        любых иных действий, предусмотренных действующим законодательством РФ
        как неавтоматизированными, так и автоматизированными способами.
      </p>
      Данное согласие дается Оператору для обработки моих персональных данных в
      следующих целях:
      <br />
      <ul className='agreement_aim-list'>
        <li>предоставление мне услуг/работ;</li>
        <li>
          направление в мой адрес уведомлений, касающихся предоставляемых
          услуг/работ;
        </li>
        <li>подготовка и направление ответов на мои запросы;</li>
        <li>
          направление в мой адрес информации, в том числе рекламной, о
          мероприятиях/товарах/услугах/работах Оператора.
        </li>
      </ul>
      <p>
        Настоящее согласие действует до момента его отзыва путем направления
        соответствующего уведомления на электронный адрес
        info@investmarketing.ru. В случае отзыва мною согласия на обработку
        персональных данных Оператор вправе продолжить обработку персональных
        данных без моего согласия при наличии оснований, указанных в пунктах 2 –
        11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального
        закона №152-ФЗ «О персональных данных» от 26.06.2006 г.
      </p>
    </div>
  );
};

export default AgreementTextRu;

import './form.scss';

import React from 'react';
import { codes } from '../../data/phoneCodes';

const Form = ({ isLight, lang, agreementOpenHandler }) => {
  const [fileName, setFileName] = React.useState('');
  const [geo, setGeo] = React.useState({})

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(r => (setGeo({...geo, lat: r.coords.latitude, long: r.coords.longitude}))) 
  }, [])

  if(geo.lat !== undefined && geo.long !== undefined){
    fetch(`https://api.opencagedata.com/geocode/v1/json?key=f009a7bf480847c992cc269b38963e66&q=${geo.lat},${geo.long}&pretty=1`)
        .then(res => res.json())
        .then(
            data => {
              setGeo(data.results[0].components['ISO_3166-1_alpha-2'])
            }
        )
  }

  return (
    <section className='form-section' id='form-section'>
      <form
        className={
          isLight ? 'form-section_form form' : 'form-section_form form--dark'
        }
        method='post'
        action='https://investmarketing.ru:8085'
        encType="multipart/form-data"
      >
        <div className='form_title-wrapper'>
          <h2 className='form_title section-title'>
            {lang === 'ru' ? 'Реализовать проект' : 'Start a project right now'}
          </h2>
        </div>
        <div
          className={
            isLight ? 'form_inputs-wrapper' : 'form_inputs-wrapper--dark'
          }
        >
          <label>
            <input
              type='text'
              placeholder={lang === 'ru' ? 'Имя' : 'Name'}
              required={true}
              name='name'
            />
          </label>
          <label>
            <input
              type='text'
              placeholder={lang === 'ru' ? 'Название компании' : 'Company name'}
              required={true}
              name='company'
            />
          </label>
          <label
            className={isLight ? 'form_phone-label' : 'form_phone-label--dark'}
          >
            <select name='codes-choose' id='code-choose' >
              {codes
                .sort((a, b) => {
                  return a.Code - b.Code;
                })
                .map((e) => {
                  return (
                    <option className='option-custom' key={e.Alpha2} value={e.Code} selected={(function() {
                      if(geo && typeof(geo) === 'string') {
                        return geo.toLowerCase() === e.Alpha2.toLowerCase()
                      }
                    })()
                      }>{e.Alpha2 + ': ' + e.Code}</option>
                  );
                })}
            </select>
            <input
              type='tel'
              pattern='[0-9]{6,}'
              placeholder={
                lang === 'ru' ? 'Контактный телефон / факс' : 'Cellphone'
              }
              required={true}
              name='phone'
            />
          </label>
          <label>
            <input
              type='email'
              placeholder='E-Mail'
              required={true}
              name='mail'
            />
          </label>
          <label className='textarea-label'>
            <textarea
              id='form_textarea'
              placeholder={lang === 'ru' ? 'Текст сообщения' : 'Message text'}
              name='message'
            />
          </label>
          <label className='form_attach-file-button-wrapper'>
            <input
              type='file'
              name='file'
              onChange={(e) => {
                setFileName(e.target.files[0].name);
              }}
            />
            <span className='form_attach-file-button'>
              {lang === 'ru' ? 'Прикрепить файл' : 'Attach a file'}
            </span>
            <div className='attatched-file-wrapper'>
              {fileName === '' ? '' : `Прикреплённые файлы: ${fileName}`}
            </div>
          </label>
          <label className='form_condition-label'>
            <input type='checkbox' required={true} name='agreement' />
            <span className='agrBtn-text'>
              {lang === 'ru' ? 'Я принимаю условия ' : 'I accept the User '}
              <span className='agrBtn' onClick={agreementOpenHandler}>
                {lang === 'ru'
                  ? 'пользовательского соглашения'
                  : 'agreement terms'}
              </span>
            </span>
          </label>
          <button
            data-sitekey='6LchiQAgAAAAAPARPe3lIEOUpSddU55RlN4sXL27'
            data-callback='onSubmit'
            data-action='submit'
            className={`g-recaptcha ${isLight ? 'form_submit-button' : 'form_submit-button--dark'}`}
          >
            {lang === 'ru' ? 'Отправить' : 'Send'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default Form;

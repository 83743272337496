import './content.scss';

import Title from './title/title';
import Partners from './partners/partners';
import Service from './service/service';
import Form from './form/form';

const Content = ({ isLight, lang, agreementOpenHandler }) => {
  return (
      <>
          <Title isLight={isLight} lang={lang} />
    <main className='content'>

      <Partners lang={lang} isLight={isLight} />
      <Partners lang={lang} isLight={isLight} />
      {/*<Service isLight={isLight} lang={lang} />*/}
      <Form
        isLight={isLight}
        lang={lang}
        agreementOpenHandler={agreementOpenHandler}
      />
    </main>
      </>
  );
};

export default Content;

import './title.scss';

import backgroundMobileWhite from '../../../assets/img/backgrounds/earth-mobile-white.jpg';
import backgroundMobileBlack from '../../../assets/img/backgrounds/earth-mobile-black.jpg';
import backgroundImage from '../../../assets/img/backgrounds/background.svg'
import backgroundDesktopWhite from '../../../assets/img/backgrounds/earth-desktop-white.png';
import backgroundDesktopBlack from '../../../assets/img/backgrounds/earth-desktop-black.png';

const Title = ({ isLight, lang }) => {
  return (
    <section className='title-section'>
      <div className='title-section_title-wrapper'>
        <h1 className='title-section_title'>
          {lang === 'ru'
            ? 'Digital'
            : 'Digital'}
        </h1>
      </div>
      <div className='title-section_bg-wrapper'>
        {/*<picture>*/}
        {/*  <source*/}
        {/*    srcSet={backgroundImage}*/}
        {/*    width={'100%'}*/}
        {/*    maxHeight={500}*/}
        {/*    media='(min-width: 600px)'*/}
        {/*  />*/}
        {/*  <img*/}
        {/*    className='title-section_bg-earth'*/}
        {/*    src={isLight ? backgroundMobileWhite : backgroundMobileBlack}*/}
        {/*    width={250}*/}
        {/*    height={409}*/}
        {/*    alt=''*/}
        {/*  />*/}
        {/*</picture>*/}
      </div>
    </section>
  );
};

export default Title;

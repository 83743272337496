import './App.scss';
import Header from "../components/header/Header";

import logoBlack from '../assets/img/logo-black.svg';
import logoWhite from '../assets/img/logo-white.svg';
import {useState} from "react";
import Content from "../components/content/content";
import Footer from "../components/footer/footer";
import Agreement from "../components/agreement/agreement";

function App() {
    const localStorageTheme = Boolean(localStorage.getItem('theme'));
    const localStorageLang = localStorage.getItem('lang');

    const [isLight, setIsLight] = useState(localStorageTheme);
    const [lang, setLang] = useState(localStorageLang || 'ru');
    const [isAgreementOpened, setIsAgreementOpened] = useState(false);
    const [isNavOpened, setIsNavOpened] = useState(false);

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > 20) {
            sessionStorage.setItem('im_scroll', window.pageYOffset);
        }
    });

    const containerClassList = [];
    if (isLight) {
        containerClassList.push('container');
    } else {
        containerClassList.push('container--dark');
    }

    if (isAgreementOpened) {
        containerClassList.push('container--fixed');
    }

    if (isNavOpened) {
        containerClassList.push('container--fixed');
    }

    const agreementOpenHandler = (e) => {
        e.preventDefault();
        setIsAgreementOpened(true);
    };

    const agreementCloseHandler = (e) => {
        e.preventDefault();
        setIsAgreementOpened(false);
        setTimeout(() => {
            window.scrollTo(0, sessionStorage.getItem('im_scroll'));
        }, 1);
    };

    const setRu = () => {
        setLang('ru');
        localStorage.setItem('lang', 'ru');
    };

    const setEng = () => {
        setLang('eng');
        localStorage.setItem('lang', 'eng');
    };

    const turnThemeLight = () => {
        setIsLight(true);
        localStorage.setItem('theme', '1');
    };

    const turnThemeDark = () => {
        setIsLight(false);
        localStorage.setItem('theme', '');
    };
  return (
    <div className={containerClassList.join(' ')}>
     <Header logoBlack={logoBlack}
             logoWhite={logoWhite}
             isLight={isLight}
             turnThemeLight={turnThemeLight}
             turnThemeDark={turnThemeDark}
             lang={lang}
             setRu={setRu}
             setEng={setEng}
             isNavOpened={isNavOpened}
             setIsNavOpened={setIsNavOpened} />
        <Content
            isLight={isLight}
            lang={lang}
            agreementOpenHandler={agreementOpenHandler}
        />
        <Footer
            logoBlack={logoBlack}
            logoWhite={logoWhite}
            isLight={isLight}
            turnThemeLight={turnThemeLight}
            turnThemeDark={turnThemeDark}
            lang={lang}
            agreementOpenHandler={agreementOpenHandler}
        />
        <Agreement
            agreementCloseHandler={agreementCloseHandler}
            isAgreementOpened={isAgreementOpened}
            isLight={isLight}
            lang={lang}
        />
    </div>
  );
}

export default App;

import './partners.scss';
import Slider from "../../slider/Slider";
import Sslider from "../../slider/Slider";


const Partners = ({ref, isLight, lang }) => {
    const scroll = () => {
        const section = document.querySelector( '.form-section' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
  return (
    <section className='partners-section'>
      <div className='partners-section_title-wrapper'>
        <h2 className='partners-section_title section-title'>
          {lang === 'ru' ? 'Дизайн приложения TELWEL' : 'Дизайн приложения TELWEL'}
        </h2>
      </div>
      <div className='slider-container'>
        <Slider />
        <div className='project-text'>
            Telwel собрали для вас наиболее популярные страны и тарифы в несколько раз ниже роуминговых планов ваших операторов.
            С помощью смартфона, планшета или компьютера с поддержкой eSIM вы сможете быть на связи с друзьями за несколько евро целый месяц!
            И при этом вам не придется менять номер телефона, где бы вы не находились!
        <div className='button-container'>
            <button onClick={scroll} className={isLight ? 'project-button white' : 'project-button'}>Заявка на проект</button>
        </div>
        </div>
      </div>
      {/*<ul className='partners-section_partners-list'>*/}
      {/*  {companies.map((item) => {*/}
      {/*    if (item.visibility) {*/}
      {/*      return (*/}
      {/*        <li*/}
      {/*          className='partners-section_partners-item partners-item'*/}
      {/*          key={companies.indexOf(item)}*/}
      {/*        >*/}
      {/*          <a href={item.link} target='_blank' rel='noreferrer'>*/}
      {/*            <img*/}
      {/*              className='partners-item_image'*/}
      {/*              src={isLight ? item.image : item.imageWhite}*/}
      {/*              alt={item.name}*/}
      {/*            />*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*      );*/}
      {/*    }*/}
      {/*    return;*/}
      {/*  })}*/}
      {/*</ul>*/}
    </section>
  );
};

export default Partners;

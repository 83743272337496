// import React from "react"
// import { useKeenSlider } from "keen-slider/react"
// import "keen-slider/keen-slider.min.css"
import "./stiles.scss"
import image from '../../assets/img/slider/cardTitle.svg'
//
// function ThumbnailPlugin(mainRef) {
//     return (slider) => {
//         function removeActive() {
//             slider.slides.forEach((slide) => {
//                 slide.classList.remove("active")
//             })
//         }
//         function addActive(idx) {
//             slider.slides[idx].classList.add("active")
//         }
//
//         function addClickEvents() {
//             slider.slides.forEach((slide, idx) => {
//                 slide.addEventListener("click", () => {
//                     if (mainRef.current) mainRef.current.moveToIdx(idx)
//                 })
//             })
//         }
//
//         slider.on("created", () => {
//             if (!mainRef.current) return
//             addActive(slider.track.details.rel)
//             addClickEvents()
//             mainRef.current.on("animationStarted", (main) => {
//                 removeActive()
//                 const next = main.animator.targetIdx || 0
//                 addActive(main.track.absToRel(next))
//                 slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
//             })
//         })
//     }
// }
//
// export default function Slider() {
//     const [sliderRef, instanceRef] = useKeenSlider({
//         initial: 0,
//     })
//     const [thumbnailRef] = useKeenSlider(
//         {
//             initial: 0,
//             slides: {
//                 perView: 4,
//                 spacing: 10,
//             },
//         },
//         [ThumbnailPlugin(instanceRef)]
//     )
//
//     return (
//         <div>
//             <div ref={sliderRef} className="keen-slider">
//                 <div className="keen-slider__slide number-slide1"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide2"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide3"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide4"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide5"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide6"><img src={image} alt=""/></div>
//             </div>
//
//             <div ref={thumbnailRef} className="keen-slider thumbnail">
//                 <div className="keen-slider__slide number-slide1"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide2"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide3"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide4"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide5"><img src={image} alt=""/></div>
//                 <div className="keen-slider__slide number-slide6"><img src={image} alt=""/></div>
//             </div>
//         </div>
//     )
// }

import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
function Slider(props) {
    return (
        <div className='slider'>
        <Splide aria-label="My Favorite Images">
            <SplideSlide>
                <img src={image} alt="Image 1"/>
            </SplideSlide>
            <SplideSlide>
                <img src={image} alt="Image 2"/>
            </SplideSlide>
            <SplideSlide>
                <img src={image} alt="Image 2"/>
            </SplideSlide>
            <SplideSlide>
                <img src={image} alt="Image 2"/>
            </SplideSlide>
        </Splide>
        </div>
    );
}

export default Slider;
export const codes = [
  {
    Name: 'Зимбабве',
    Full_name: 'Республика Зимбабве',
    English_name: 'Zimbabwe',
    Alpha2: 'ZW',
    Alpha3: 'ZWE',
    Code: 263,
  },
  {
    Name: 'Багамы',
    Full_name: 'Содружество Багамы',
    English_name: 'Bahamas',
    Alpha2: 'BS',
    Alpha3: 'BHS',
    Code: '+1-242 ',
  },
  {
    Name: 'Барбадос',
    English_name: 'Barbados',
    Alpha2: 'BB',
    Alpha3: 'BRB',
    Code: '+1-246 ',
  },
  {
    Name: 'Ангилья',
    English_name: 'Anguilla',
    Alpha2: 'AI',
    Alpha3: 'AIA',
    Code: '+1-264 ',
  },
  {
    Name: 'Антигуа и Барбуда',
    English_name: 'Antigua and Barbuda',
    Alpha2: 'AG',
    Alpha3: 'ATG',
    Code: '+1-268 ',
  },
  {
    Name: 'Острова Кайман',
    English_name: 'Cayman Islands',
    Alpha2: 'KY',
    Alpha3: 'CYM',
    Code: '+1-345 ',
  },
  {
    Name: 'Бермуды',
    English_name: 'Bermuda',
    Alpha2: 'BM',
    Alpha3: 'BMU',
    Code: '+1-441 ',
  },
  {
    Name: 'Гренада',
    English_name: 'Grenada',
    Alpha2: 'GD',
    Alpha3: 'GRD',
    Code: '+1-473 ',
  },
  {
    Name: 'Монтсеррат',
    English_name: 'Montserrat',
    Alpha2: 'MS',
    Alpha3: 'MSR',
    Code: '+1-664 ',
  },
  {
    Name: 'Гуам',
    English_name: 'Guam',
    Alpha2: 'GU',
    Alpha3: 'GUM',
    Code: '+1-671, +671 ',
  },
  {
    Name: 'Мексика',
    Full_name: 'Мексиканские Соединенные Штаты',
    English_name: 'Mexico',
    Alpha2: 'MX',
    Alpha3: 'MEX',
    Code: '+1-706, +1-905, +52 ',
  },
  {
    Name: 'Доминика',
    Full_name: 'Содружество Доминики',
    English_name: 'Dominica',
    Alpha2: 'DM',
    Alpha3: 'DMA',
    Code: '+1-767 ',
  },
  {
    Name: 'Пуэрто-Рико',
    English_name: 'Puerto Rico',
    Alpha2: 'PR',
    Alpha3: 'PRI',
    Code: '+1-787 ',
  },
  {
    Name: 'Доминиканская Республика',
    English_name: 'Dominican Republic',
    Alpha2: 'DO',
    Alpha3: 'DOM',
    Code: '+1-809 ',
  },
  {
    Name: 'Тринидад и Тобаго',
    Full_name: 'Республика Тринидад и Тобаго',
    English_name: 'Trinidad and Tobago',
    Alpha2: 'TT',
    Alpha3: 'TTO',
    Code: '+1-868 ',
  },
  {
    Name: 'Ямайка',
    English_name: 'Jamaica',
    Alpha2: 'JM',
    Alpha3: 'JAM',
    Code: '+1-876 ',
  },
  {
    Name: 'Египет',
    Full_name: 'Арабская Республика Египет',
    English_name: 'Egypt',
    Alpha2: 'EG',
    Alpha3: 'EGY',
    Code: '+20 ',
  },
  {
    Name: 'Алжир',
    Full_name: 'Алжирская Народная Демократическая Республика',
    English_name: 'Algeria',
    Alpha2: 'DZ',
    Alpha3: 'DZA',
    Code: '+21 ',
  },
  {
    Name: 'Западная Сахара',
    English_name: 'Western Sahara',
    Alpha2: 'EH',
    Alpha3: 'ESH',
    Code: '+21 ',
  },
  {
    Name: 'Тунис',
    Full_name: 'Тунисская Республика',
    English_name: 'Tunisia',
    Alpha2: 'TN',
    Alpha3: 'TUN',
    Code: '+21 ',
  },
  {
    Name: 'Марокко',
    Full_name: 'Королевство Марокко',
    English_name: 'Morocco',
    Alpha2: 'MA',
    Alpha3: 'MAR',
    Code: '+212 ',
  },
  {
    Name: 'Гамбия',
    Full_name: 'Республика Гамбия',
    English_name: 'Gambia',
    Alpha2: 'GM',
    Alpha3: 'GMB',
    Code: '+220 ',
  },
  {
    Name: 'Сенегал',
    Full_name: 'Республика Сенегал',
    English_name: 'Senegal',
    Alpha2: 'SN',
    Alpha3: 'SEN',
    Code: '+221 ',
  },
  {
    Name: 'Мавритания',
    Full_name: 'Исламская Республика Мавритания',
    English_name: 'Mauritania',
    Alpha2: 'MR',
    Alpha3: 'MRT',
    Code: '+222 ',
  },
  {
    Name: 'Мали',
    Full_name: 'Республика Мали',
    English_name: 'Mali',
    Alpha2: 'ML',
    Alpha3: 'MLI',
    Code: '+223 ',
  },
  {
    Name: 'Гвинея',
    Full_name: 'Гвинейская Республика',
    English_name: 'Guinea',
    Alpha2: 'GN',
    Alpha3: 'GIN',
    Code: '+224 ',
  },
  {
    Name: 'Буркина-Фасо',
    English_name: 'Burkina Faso',
    Alpha2: 'BF',
    Alpha3: 'BFA',
    Code: '+226 ',
  },
  {
    Name: 'Нигер',
    Full_name: 'Республика Нигер',
    English_name: 'Niger',
    Alpha2: 'NE',
    Alpha3: 'NER',
    Code: '+227 ',
  },
  {
    Name: 'Бенин',
    Full_name: 'Республика Бенин',
    English_name: 'Benin',
    Alpha2: 'BJ',
    Alpha3: 'BEN',
    Code: '+229 ',
  },
  {
    Name: 'Маврикий',
    Full_name: 'Республика Маврикий',
    English_name: 'Mauritius',
    Alpha2: 'MU',
    Alpha3: 'MUS',
    Code: '+230 ',
  },
  {
    Name: 'Либерия',
    Full_name: 'Республика Либерия',
    English_name: 'Liberia',
    Alpha2: 'LR',
    Alpha3: 'LBR',
    Code: '+231 ',
  },
  {
    Name: 'Сьерра-Леоне',
    Full_name: 'Республика Сьерра-Леоне',
    English_name: 'Sierra Leone',
    Alpha2: 'SL',
    Alpha3: 'SLE',
    Code: '+232 ',
  },
  {
    Name: 'Гана',
    Full_name: 'Республика Гана',
    English_name: 'Ghana',
    Alpha2: 'GH',
    Alpha3: 'GHA',
    Code: '+233 ',
  },
  {
    Name: 'Нигерия',
    Full_name: 'Федеративная Республика Нигерия',
    English_name: 'Nigeria',
    Alpha2: 'NG',
    Alpha3: 'NGA',
    Code: '+234 ',
  },
  {
    Name: 'Чад',
    Full_name: 'Республика Чад',
    English_name: 'Chad',
    Alpha2: 'TD',
    Alpha3: 'TCD',
    Code: '+235 ',
  },
  {
    Name: 'Центрально-Африканская Республика',
    English_name: 'Central African Republic',
    Alpha2: 'CF',
    Alpha3: 'CAF',
    Code: '+236 ',
  },
  {
    Name: 'Камерун',
    Full_name: 'Республика Камерун',
    English_name: 'Cameroon',
    Alpha2: 'CM',
    Alpha3: 'CMR',
    Code: '+237 ',
  },
  {
    Name: 'Кабо-Верде',
    Full_name: 'Республика Кабо-Верде',
    English_name: 'Cape Verde',
    Alpha2: 'CV',
    Alpha3: 'CPV',
    Code: '+238 ',
  },
  {
    Name: 'Экваториальная Гвинея',
    Full_name: 'Республика Экваториальная Гвинея',
    English_name: 'Equatorial Guinea',
    Alpha2: 'GQ',
    Alpha3: 'GNQ',
    Code: '+240 ',
  },
  {
    Name: 'Габон',
    Full_name: 'Габонская Республика',
    English_name: 'Gabon',
    Alpha2: 'GA',
    Alpha3: 'GAB',
    Code: '+241 ',
  },
  {
    Name: 'Конго',
    Full_name: 'Республика Конго',
    English_name: 'Congo',
    Alpha2: 'CG',
    Alpha3: 'COG',
    Code: '+242 ',
  },
  {
    Name: 'Ангола',
    Full_name: 'Республика Ангола',
    English_name: 'Angola',
    Alpha2: 'AO',
    Alpha3: 'AGO',
    Code: '+244 ',
  },
  {
    Name: 'Гвинея-Бисау',
    Full_name: 'Республика Гвинея-Бисау',
    English_name: 'Guinea-Bissau',
    Alpha2: 'GW',
    Alpha3: 'GNB',
    Code: '+245 ',
  },
  {
    Name: 'Сейшелы',
    Full_name: 'Республика Сейшелы',
    English_name: 'Seychelles',
    Alpha2: 'SC',
    Alpha3: 'SYC',
    Code: '+248 ',
  },
  {
    Name: 'Судан',
    Full_name: 'Республика Судан',
    English_name: 'Sudan',
    Alpha2: 'SD',
    Alpha3: 'SDN',
    Code: '+249 ',
  },
  {
    Name: 'Эфиопия',
    Full_name: 'Федеративная Демократическая Республика Эфиопия',
    English_name: 'Ethiopia',
    Alpha2: 'ET',
    Alpha3: 'ETH',
    Code: '+251 ',
  },
  {
    Name: 'Сомали',
    Full_name: 'Сомалийская Республика',
    English_name: 'Somalia',
    Alpha2: 'SO',
    Alpha3: 'SOM',
    Code: '+252 ',
  },
  {
    Name: 'Джибути',
    Full_name: 'Республика Джибути',
    English_name: 'Djibouti',
    Alpha2: 'DJ',
    Alpha3: 'DJI',
    Code: '+253 ',
  },
  {
    Name: 'Кения',
    Full_name: 'Республика Кения',
    English_name: 'Kenya',
    Alpha2: 'KE',
    Alpha3: 'KEN',
    Code: '+254 ',
  },
  {
    Name: 'Уганда',
    Full_name: 'Республика Уганда',
    English_name: 'Uganda',
    Alpha2: 'UG',
    Alpha3: 'UGA',
    Code: '+256 ',
  },
  {
    Name: 'Бурунди',
    Full_name: 'Республика Бурунди',
    English_name: 'Burundi',
    Alpha2: 'BI',
    Alpha3: 'BDI',
    Code: '+257 ',
  },
  {
    Name: 'Мозамбик',
    Full_name: 'Республика Мозамбик',
    English_name: 'Mozambique',
    Alpha2: 'MZ',
    Alpha3: 'MOZ',
    Code: '+258 ',
  },
  {
    Name: 'Замбия',
    Full_name: 'Республика Замбия',
    English_name: 'Zambia',
    Alpha2: 'ZM',
    Alpha3: 'ZMB',
    Code: '+260 ',
  },
  {
    Name: 'Мадагаскар',
    Full_name: 'Республика Мадагаскар',
    English_name: 'Madagascar',
    Alpha2: 'MG',
    Alpha3: 'MDG',
    Code: '+261 ',
  },
  {
    Name: 'Реюньон',
    English_name: 'Reunion',
    Alpha2: 'RE',
    Alpha3: 'REU',
    Code: '+262 ',
  },
  {
    Name: 'Намибия',
    Full_name: 'Республика Намибия',
    English_name: 'Namibia',
    Alpha2: 'NA',
    Alpha3: 'NAM',
    Code: '+264 ',
  },
  {
    Name: 'Малави',
    Full_name: 'Республика Малави',
    English_name: 'Malawi',
    Alpha2: 'MW',
    Alpha3: 'MWI',
    Code: '+265 ',
  },
  {
    Name: 'Лесото',
    Full_name: 'Королевство Лесото',
    English_name: 'Lesotho',
    Alpha2: 'LS',
    Alpha3: 'LSO',
    Code: '+266 ',
  },
  {
    Name: 'Ботсвана',
    Full_name: 'Республика Ботсвана',
    English_name: 'Botswana',
    Alpha2: 'BW',
    Alpha3: 'BWA',
    Code: '+267 ',
  },
  {
    Name: 'Южная Африка',
    Full_name: 'Южно-Африканская Республика',
    English_name: 'South Africa',
    Alpha2: 'ZA',
    Alpha3: 'ZAF',
    Code: '+27 ',
  },
  {
    Name: 'Гренландия',
    English_name: 'Greenland',
    Alpha2: 'GL',
    Alpha3: 'GRL',
    Code: '+299 ',
  },
  {
    Name: 'Греция',
    Full_name: 'Греческая Республика',
    English_name: 'Greece',
    Alpha2: 'GR',
    Alpha3: 'GRC',
    Code: '+30 ',
  },
  {
    Name: 'Нидерланды',
    Full_name: 'Королевство Нидерландов',
    English_name: 'Netherlands',
    Alpha2: 'NL',
    Alpha3: 'NLD',
    Code: '+31 ',
  },
  {
    Name: 'Бельгия',
    Full_name: 'Королевство Бельгии',
    English_name: 'Belgium',
    Alpha2: 'BE',
    Alpha3: 'BEL',
    Code: '+32 ',
  },
  {
    Name: 'Франция',
    Full_name: 'Французская Республика',
    English_name: 'France',
    Alpha2: 'FR',
    Alpha3: 'FRA',
    Code: '+33 ',
  },
  {
    Name: 'Испания',
    Full_name: 'Королевство Испания',
    English_name: 'Spain',
    Alpha2: 'ES',
    Alpha3: 'ESP',
    Code: '+34 ',
  },
  {
    Name: 'Гибралтар',
    English_name: 'Gibraltar',
    Alpha2: 'GI',
    Alpha3: 'GIB',
    Code: '+350 ',
  },
  {
    Name: 'Португалия',
    Full_name: 'Португальская Республика',
    English_name: 'Portugal',
    Alpha2: 'PT',
    Alpha3: 'PRT',
    Code: '+351 ',
  },
  {
    Name: 'Люксембург',
    Full_name: 'Великое Герцогство Люксембург',
    English_name: 'Luxembourg',
    Alpha2: 'LU',
    Alpha3: 'LUX',
    Code: '+352 ',
  },
  {
    Name: 'Исландия',
    Full_name: 'Республика Исландия',
    English_name: 'Iceland',
    Alpha2: 'IS',
    Alpha3: 'ISL',
    Code: '+354 ',
  },
  {
    Name: 'Албания',
    Full_name: 'Республика Албания',
    English_name: 'Albania',
    Alpha2: 'AL',
    Alpha3: 'ALB',
    Code: '+355 ',
  },
  {
    Name: 'Мальта',
    Full_name: 'Республика Мальта',
    English_name: 'Malta',
    Alpha2: 'MT',
    Alpha3: 'MLT',
    Code: '+356 ',
  },
  {
    Name: 'Кипр',
    Full_name: 'Республика Кипр',
    English_name: 'Cyprus',
    Alpha2: 'CY',
    Alpha3: 'CYP',
    Code: '+357 ',
  },
  {
    Name: 'Финляндия',
    Full_name: 'Финляндская Республика',
    English_name: 'Finland',
    Alpha2: 'FI',
    Alpha3: 'FIN',
    Code: '+358 ',
  },
  {
    Name: 'Болгария',
    Full_name: 'Республика Болгария',
    English_name: 'Bulgaria',
    Alpha2: 'BG',
    Alpha3: 'BGR',
    Code: '+359 ',
  },
  {
    Name: 'Венгрия',
    Full_name: 'Венгерская Республика',
    English_name: 'Hungary',
    Alpha2: 'HU',
    Alpha3: 'HUN',
    Code: '+36 ',
  },
  {
    Name: 'Литва',
    Full_name: 'Литовская Республика',
    English_name: 'Lithuania',
    Alpha2: 'LT',
    Alpha3: 'LTU',
    Code: '+370 ',
  },
  {
    Name: 'Латвия',
    Full_name: 'Латвийская Республика',
    English_name: 'Latvia',
    Alpha2: 'LV',
    Alpha3: 'LVA',
    Code: '+371 ',
  },
  {
    Name: 'Эстония',
    Full_name: 'Эстонская Республика',
    English_name: 'Estonia',
    Alpha2: 'EE',
    Alpha3: 'EST',
    Code: '+372 ',
  },
  {
    Name: 'Беларусь',
    Full_name: 'Республика Беларусь',
    English_name: 'Belarus',
    Alpha2: 'BY',
    Alpha3: 'BLR',
    Code: '+375 ',
  },
  {
    Name: 'Андорра',
    Full_name: 'Княжество Андорра',
    English_name: 'Andorra',
    Alpha2: 'AD',
    Alpha3: 'AND',
    Code: '+376 ',
  },
  {
    Name: 'Монако',
    Full_name: 'Княжество Монако',
    English_name: 'Monaco',
    Alpha2: 'MC',
    Alpha3: 'MCO',
    Code: '+377 ',
  },
  {
    Name: 'Украина',
    English_name: 'Ukraine',
    Alpha2: 'UA',
    Alpha3: 'UKR',
    Code: '+380 ',
  },
  {
    Name: 'Хорватия',
    Full_name: 'Республика Хорватия',
    English_name: 'Croatia',
    Alpha2: 'HR',
    Alpha3: 'HRV',
    Code: '+385 ',
  },
  {
    Name: 'Словения',
    Full_name: 'Республика Словения',
    English_name: 'Slovenia',
    Alpha2: 'SI',
    Alpha3: 'SVN',
    Code: '+386 ',
  },
  {
    Name: 'Босния и Герцеговина',
    English_name: 'Bosnia and Herzegovina',
    Alpha2: 'BA',
    Alpha3: 'BIH',
    Code: '+387 ',
  },
  {
    Name: 'Италия',
    Full_name: 'Итальянская Республика',
    English_name: 'Italy',
    Alpha2: 'IT',
    Alpha3: 'ITA',
    Code: '+39 ',
  },
  {
    Name: 'Сан-Марино',
    Full_name: 'Республика Сан-Марино',
    English_name: 'San Marino',
    Alpha2: 'SM',
    Alpha3: 'SMR',
    Code: '+39 ',
  },
  {
    Name: 'Румыния',
    English_name: 'Romania',
    Alpha2: 'RO',
    Alpha3: 'ROU',
    Code: '+40 ',
  },
  {
    Name: 'Лихтенштейн',
    Full_name: 'Княжество Лихтенштейн',
    English_name: 'Liechtenstein',
    Alpha2: 'LI',
    Alpha3: 'LIE',
    Code: '+41 ',
  },
  {
    Name: 'Швейцария',
    Full_name: 'Швейцарская Конфедерация',
    English_name: 'Switzerland',
    Alpha2: 'CH',
    Alpha3: 'CHE',
    Code: '+41 ',
  },
  {
    Name: 'Чешская Республика',
    English_name: 'Czech Republic',
    Alpha2: 'CZ',
    Alpha3: 'CZE',
    Code: '+420 ',
  },
  {
    Name: 'Словакия',
    Full_name: 'Словацкая Республика',
    English_name: 'Slovakia',
    Alpha2: 'SK',
    Alpha3: 'SVK',
    Code: '+421 ',
  },
  {
    Name: 'Австрия',
    Full_name: 'Австрийская Республика',
    English_name: 'Austria',
    Alpha2: 'AT',
    Alpha3: 'AUT',
    Code: '+43 ',
  },
  {
    Name: 'Соединенное Королевство',
    Full_name: 'Соединенное Королевство Великобритании и Северной Ирландии',
    English_name: 'United Kingdom',
    Alpha2: 'GB',
    Alpha3: 'GBR',
    Code: '+44 ',
  },
  {
    Name: 'Дания',
    Full_name: 'Королевство Дания',
    English_name: 'Denmark',
    Alpha2: 'DK',
    Alpha3: 'DNK',
    Code: '+45 ',
  },
  {
    Name: 'Швеция',
    Full_name: 'Королевство Швеция',
    English_name: 'Sweden',
    Alpha2: 'SE',
    Alpha3: 'SWE',
    Code: '+46 ',
  },
  {
    Name: 'Норвегия',
    Full_name: 'Королевство Норвегия',
    English_name: 'Norway',
    Alpha2: 'NO',
    Alpha3: 'NOR',
    Code: '+47 ',
  },
  {
    Name: 'Польша',
    Full_name: 'Республика Польша',
    English_name: 'Poland',
    Alpha2: 'PL',
    Alpha3: 'POL',
    Code: '+48 ',
  },
  {
    Name: 'Германия',
    Full_name: 'Федеративная Республика Германия',
    English_name: 'Germany',
    Alpha2: 'DE',
    Alpha3: 'DEU',
    Code: '+49 ',
  },
  {
    Name: 'Белиз',
    English_name: 'Belize',
    Alpha2: 'BZ',
    Alpha3: 'BLZ',
    Code: '+501 ',
  },
  {
    Name: 'Гватемала',
    Full_name: 'Республика Гватемала',
    English_name: 'Guatemala',
    Alpha2: 'GT',
    Alpha3: 'GTM',
    Code: '+502 ',
  },
  {
    Name: 'Эль-Сальвадор',
    Full_name: 'Республика Эль-Сальвадор',
    English_name: 'El Salvador',
    Alpha2: 'SV',
    Alpha3: 'SLV',
    Code: '+503 ',
  },
  {
    Name: 'Гондурас',
    Full_name: 'Республика Гондурас',
    English_name: 'Honduras',
    Alpha2: 'HN',
    Alpha3: 'HND',
    Code: '+504 ',
  },
  {
    Name: 'Никарагуа',
    Full_name: 'Республика Никарагуа',
    English_name: 'Nicaragua',
    Alpha2: 'NI',
    Alpha3: 'NIC',
    Code: '+505 ',
  },
  {
    Name: 'Коста-Рика',
    Full_name: 'Республика Коста-Рика',
    English_name: 'Costa Rica',
    Alpha2: 'CR',
    Alpha3: 'CRI',
    Code: '+506 ',
  },
  {
    Name: 'Панама',
    Full_name: 'Республика Панама',
    English_name: 'Panama',
    Alpha2: 'PA',
    Alpha3: 'PAN',
    Code: '+507 ',
  },
  {
    Name: 'Гаити',
    Full_name: 'Республика Гаити',
    English_name: 'Haiti',
    Alpha2: 'HT',
    Alpha3: 'HTI',
    Code: '+509 ',
  },
  {
    Name: 'Перу',
    Full_name: 'Республика Перу',
    English_name: 'Peru',
    Alpha2: 'PE',
    Alpha3: 'PER',
    Code: '+51 ',
  },
  {
    Name: 'Куба',
    Full_name: 'Республика Куба',
    English_name: 'Cuba',
    Alpha2: 'CU',
    Alpha3: 'CUB',
    Code: '+53 ',
  },
  {
    Name: 'Аргентина',
    Full_name: 'Аргентинская Республика',
    English_name: 'Argentina',
    Alpha2: 'AR',
    Alpha3: 'ARG',
    Code: '+54 ',
  },
  {
    Name: 'Бразилия',
    Full_name: 'Федеративная Республика Бразилия',
    English_name: 'Brazil',
    Alpha2: 'BR',
    Alpha3: 'BRA',
    Code: '+55 ',
  },
  {
    Name: 'Чили',
    Full_name: 'Республика Чили',
    English_name: 'Chile',
    Alpha2: 'CL',
    Alpha3: 'CHL',
    Code: '+56 ',
  },
  {
    Name: 'Колумбия',
    Full_name: 'Республика Колумбия',
    English_name: 'Colombia',
    Alpha2: 'CO',
    Alpha3: 'COL',
    Code: '+57 ',
  },
  {
    Name: 'Венесуэла Боливарианская Республика',
    Full_name: 'Боливарийская Республика Венесуэла',
    English_name: 'Venezuela',
    Alpha2: 'VE',
    Alpha3: 'VEN',
    Code: '+58 ',
  },
  {
    Name: 'Гайана',
    Full_name: 'Республика Гайана',
    English_name: 'Guyana',
    Alpha2: 'GY',
    Alpha3: 'GUY',
    Code: '+592 ',
  },
  {
    Name: 'Эквадор',
    Full_name: 'Республика Эквадор',
    English_name: 'Ecuador',
    Alpha2: 'EC',
    Alpha3: 'ECU',
    Code: '+593 ',
  },
  {
    Name: 'Французская Гвиана',
    English_name: 'French Guiana',
    Alpha2: 'GF',
    Alpha3: 'GUF',
    Code: '+594 ',
  },
  {
    Name: 'Парагвай',
    Full_name: 'Республика Парагвай',
    English_name: 'Paraguay',
    Alpha2: 'PY',
    Alpha3: 'PRY',
    Code: '+595 ',
  },
  {
    Name: 'Мартиника',
    English_name: 'Martinique',
    Alpha2: 'MQ',
    Alpha3: 'MTQ',
    Code: '+596 ',
  },
  {
    Name: 'Суринам',
    Full_name: 'Республика Суринам',
    English_name: 'Suriname',
    Alpha2: 'SR',
    Alpha3: 'SUR',
    Code: '+597 ',
  },
  {
    Name: 'Уругвай',
    Full_name: 'Восточная Республика Уругвай',
    English_name: 'Uruguay',
    Alpha2: 'UY',
    Alpha3: 'URY',
    Code: '+598 ',
  },
  {
    Name: 'Малайзия',
    English_name: 'Malaysia',
    Alpha2: 'MY',
    Alpha3: 'MYS',
    Code: '+60 ',
  },
  {
    Name: 'Австралия',
    English_name: 'Australia',
    Alpha2: 'AU',
    Alpha3: 'AUS',
    Code: '+61 ',
  },
  {
    Name: 'Индонезия',
    Full_name: 'Республика Индонезия',
    English_name: 'Indonesia',
    Alpha2: 'ID',
    Alpha3: 'IDN',
    Code: '+62 ',
  },
  {
    Name: 'Филиппины',
    Full_name: 'Республика Филиппины',
    English_name: 'Philippines',
    Alpha2: 'PH',
    Alpha3: 'PHL',
    Code: '+63 ',
  },
  {
    Name: 'Новая Зеландия',
    English_name: 'New Zealand',
    Alpha2: 'NZ',
    Alpha3: 'NZL',
    Code: '+64 ',
  },
  {
    Name: 'Сингапур',
    Full_name: 'Республика Сингапур',
    English_name: 'Singapore',
    Alpha2: 'SG',
    Alpha3: 'SGP',
    Code: '+65 ',
  },
  {
    Name: 'Таиланд',
    Full_name: 'Королевство Таиланд',
    English_name: 'Thailand',
    Alpha2: 'TH',
    Alpha3: 'THA',
    Code: '+66 ',
  },
  {
    Name: 'Северные Марианские острова',
    Full_name: 'Содружество Северных Марианских островов',
    English_name: 'Northern Mariana Islands',
    Alpha2: 'MP',
    Alpha3: 'MNP',
    Code: '+670 ',
  },
  {
    Name: 'Остров Норфолк',
    English_name: 'Norfolk Island',
    Alpha2: 'NF',
    Alpha3: 'NFK',
    Code: '+672 ',
  },
  {
    Name: 'Остров Рождества',
    English_name: 'Christmas Island',
    Alpha2: 'CX',
    Alpha3: 'CXR',
    Code: '+672 ',
  },
  {
    Name: 'Науру',
    Full_name: 'Республика Науру',
    English_name: 'Nauru',
    Alpha2: 'NR',
    Alpha3: 'NRU',
    Code: '+674 ',
  },
  {
    Name: 'Папуа-Новая Гвинея',
    English_name: 'Papua New Guinea',
    Alpha2: 'PG',
    Alpha3: 'PNG',
    Code: '+675 ',
  },
  {
    Name: 'Тонга',
    Full_name: 'Королевство Тонга',
    English_name: 'Tonga',
    Alpha2: 'TO',
    Alpha3: 'TON',
    Code: '+676 ',
  },
  {
    Name: 'Соломоновы острова',
    English_name: 'Solomon Islands',
    Alpha2: 'SB',
    Alpha3: 'SLB',
    Code: '+677 ',
  },
  {
    Name: 'Вануату',
    Full_name: 'Республика Вануату',
    English_name: 'Vanuatu',
    Alpha2: 'VU',
    Alpha3: 'VUT',
    Code: '+678 ',
  },
  {
    Name: 'Фиджи',
    Full_name: 'Республика островов Фиджи',
    English_name: 'Fiji',
    Alpha2: 'FJ',
    Alpha3: 'FJI',
    Code: '+679 ',
  },
  {
    Name: 'Острова Кука',
    English_name: 'Cook Islands',
    Alpha2: 'CK',
    Alpha3: 'COK',
    Code: '+682 ',
  },
  {
    Name: 'Ниуэ',
    Full_name: 'Республика Ниуэ',
    English_name: 'Niue',
    Alpha2: 'NU',
    Alpha3: 'NIU',
    Code: '+683 ',
  },
  {
    Name: 'Американское Самоа',
    English_name: 'American Samoa',
    Alpha2: 'AS',
    Alpha3: 'ASM',
    Code: '+684 ',
  },
  {
    Name: 'Новая Каледония',
    English_name: 'New Caledonia',
    Alpha2: 'NC',
    Alpha3: 'NCL',
    Code: '+687 ',
  },
  {
    Name: 'Тувалу',
    English_name: 'Tuvalu',
    Alpha2: 'TV',
    Alpha3: 'TUV',
    Code: '+688 ',
  },
  {
    Name: 'Французская Полинезия',
    English_name: 'French Polynesia',
    Alpha2: 'PF',
    Alpha3: 'PYF',
    Code: '+689 ',
  },
  {
    Name: 'Токелау',
    English_name: 'Tokelau',
    Alpha2: 'TK',
    Alpha3: 'TKL',
    Code: '+690 ',
  },
  {
    Name: 'Маршалловы острова',
    Full_name: 'Республика Маршалловы острова',
    English_name: 'Marshall Islands',
    Alpha2: 'MH',
    Alpha3: 'MHL',
    Code: '+692 ',
  },
  {
    Name: 'Япония',
    English_name: 'Japan',
    Alpha2: 'JP',
    Alpha3: 'JPN',
    Code: '+81 ',
  },
  {
    Name: 'Вьетнам',
    Full_name: 'Социалистическая Республика Вьетнам',
    English_name: 'Vietnam',
    Alpha2: 'VN',
    Alpha3: 'VNM',
    Code: '+84 ',
  },
  {
    Name: 'Гонконг',
    Full_name: 'Специальный административный регион Китая Гонконг',
    English_name: 'Hong Kong',
    Alpha2: 'HK',
    Alpha3: 'HKG',
    Code: '+852 ',
  },
  {
    Name: 'Макао',
    Full_name: 'Специальный административный регион Китая Макао',
    English_name: 'Macao',
    Alpha2: 'MO',
    Alpha3: 'MAC',
    Code: '+853 ',
  },
  {
    Name: 'Камбоджа',
    Full_name: 'Королевство Камбоджа',
    English_name: 'Cambodia',
    Alpha2: 'KH',
    Alpha3: 'KHM',
    Code: '+855 ',
  },
  {
    Name: 'Китай',
    Full_name: 'Китайская Народная Республика',
    English_name: 'China',
    Alpha2: 'CN',
    Alpha3: 'CHN',
    Code: '+86 ',
  },
  {
    Name: 'Бангладеш',
    Full_name: 'Народная Республика Бангладеш',
    English_name: 'Bangladesh',
    Alpha2: 'BD',
    Alpha3: 'BGD',
    Code: '+880 ',
  },
  {
    Name: 'Турция',
    Full_name: 'Турецкая Республика',
    English_name: 'Turkey',
    Alpha2: 'TR',
    Alpha3: 'TUR',
    Code: '+90 ',
  },
  {
    Name: 'Индия',
    Full_name: 'Республика Индия',
    English_name: 'India',
    Alpha2: 'IN',
    Alpha3: 'IND',
    Code: '+91 ',
  },
  {
    Name: 'Пакистан',
    Full_name: 'Исламская Республика Пакистан',
    English_name: 'Pakistan',
    Alpha2: 'PK',
    Alpha3: 'PAK',
    Code: '+92 ',
  },
  {
    Name: 'Афганистан',
    Full_name: 'Переходное Исламское Государство Афганистан',
    English_name: 'Afghanistan',
    Alpha2: 'AF',
    Alpha3: 'AFG',
    Code: '+93 ',
  },
  {
    Name: 'Шри-Ланка',
    Full_name: 'Демократическая Социалистическая Республика Шри-Ланка',
    English_name: 'Sri Lanka',
    Alpha2: 'LK',
    Alpha3: 'LKA',
    Code: '+94 ',
  },
  {
    Name: 'Мальдивы',
    Full_name: 'Мальдивская Республика',
    English_name: 'Maldives',
    Alpha2: 'MV',
    Alpha3: 'MDV',
    Code: '+960 ',
  },
  {
    Name: 'Ливан',
    Full_name: 'Ливанская Республика',
    English_name: 'Lebanon',
    Alpha2: 'LB',
    Alpha3: 'LBN',
    Code: '+961 ',
  },
  {
    Name: 'Иордания',
    Full_name: 'Иорданское Хашимитское Королевство',
    English_name: 'Jordan',
    Alpha2: 'JO',
    Alpha3: 'JOR',
    Code: '+962 ',
  },
  {
    Name: 'Ирак',
    Full_name: 'Республика Ирак',
    English_name: 'Iraq',
    Alpha2: 'IQ',
    Alpha3: 'IRQ',
    Code: '+964 ',
  },
  {
    Name: 'Кувейт',
    Full_name: 'Государство Кувейт',
    English_name: 'Kuwait',
    Alpha2: 'KW',
    Alpha3: 'KWT',
    Code: '+965 ',
  },
  {
    Name: 'Саудовская Аравия',
    Full_name: 'Королевство Саудовская Аравия',
    English_name: 'Saudi Arabia',
    Alpha2: 'SA',
    Alpha3: 'SAU',
    Code: '+966 ',
  },
  {
    Name: 'Оман',
    Full_name: 'Султанат Оман',
    English_name: 'Oman',
    Alpha2: 'OM',
    Alpha3: 'OMN',
    Code: '+968 ',
  },
  {
    Name: 'Объединенные Арабские Эмираты',
    English_name: 'United Arab Emirates',
    Alpha2: 'AE',
    Alpha3: 'ARE',
    Code: '+971 ',
  },
  {
    Name: 'Израиль',
    Full_name: 'Государство Израиль',
    English_name: 'Israel',
    Alpha2: 'IL',
    Alpha3: 'ISR',
    Code: '+972 ',
  },
  {
    Name: 'Бахрейн',
    Full_name: 'Королевство Бахрейн',
    English_name: 'Bahrain',
    Alpha2: 'BH',
    Alpha3: 'BHR',
    Code: '+973 ',
  },
  {
    Name: 'Катар',
    Full_name: 'Государство Катар',
    English_name: 'Qatar',
    Alpha2: 'QA',
    Alpha3: 'QAT',
    Code: '+974 ',
  },
  {
    Name: 'Монголия',
    English_name: 'Mongolia',
    Alpha2: 'MN',
    Alpha3: 'MNG',
    Code: '+976 ',
  },
  {
    Name: 'Непал',
    Full_name: 'Королевство Непал',
    English_name: 'Nepal',
    Alpha2: 'NP',
    Alpha3: 'NPL',
    Code: '+977 ',
  },
  {
    Name: 'Грузия',
    English_name: 'Georgia',
    Alpha2: 'GE',
    Alpha3: 'GEO',
    Code: '+995 ',
  },
  {
    Name: 'Россия',
    English_name: 'Russia',
    Alpha2: 'RU',
    Alpha3: 'RUS',
    Code: '+7',
  },
  {
    Name: 'США',
    English_name: 'USA',
    Alpha2: 'US',
    Alpha3: 'USA',
    Code: '+1',
  }
];

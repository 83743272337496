import './service.scss';

import cart from '../../.././assets/img/service-icons/cart.svg';
import globe from '../../.././assets/img/service-icons/globe.svg';
import graphic from '../../.././assets/img/service-icons/graphic.svg';
import info from '../../.././assets/img/service-icons/info.svg';
import pen from '../../.././assets/img/service-icons/pen.svg';
import people from '../../.././assets/img/service-icons/people.svg';
import fintech from '../../.././assets/img/service-icons/fintech.svg';
import safety from '../../.././assets/img/service-icons/safety.svg';
import blockchain from '../../.././assets/img/service-icons/blockchain.svg';
import cartDark from '../../.././assets/img/service-icons/cart-dark.svg';
import globeDark from '../../.././assets/img/service-icons/globe-dark.svg';
import graphicDark from '../../.././assets/img/service-icons/graphic-dark.svg';
import infoDark from '../../.././assets/img/service-icons/info-dark.svg';
import penDark from '../../.././assets/img/service-icons/pen-dark.svg';
import peopleDark from '../../.././assets/img/service-icons/people-dark.svg';
import fintechDark from '../../.././assets/img/service-icons/fintech-dark.svg';
import safetyDark from '../../.././assets/img/service-icons/safety-dark.svg';
import blockchainDark from '../../.././assets/img/service-icons/blockchain-dark.svg';

const service = [
  {
    image: people,
    imageDark: peopleDark,
    text: window.innerWidth > 1300 ? 'Event' : 'Event',
    textEng: 'Event management',
  },
  {
    image: pen,
    imageDark: penDark,
    text:
      window.innerWidth > 1300
        ? 'Брендинг \n и маркетинг-стратегии'
        : 'Брендинг и маркетинг',
    textEng: 'Branding and marketing strategies',
  },
  {
    image: globe,
    imageDark: globeDark,
    text:
      window.innerWidth > 1300
        ? 'Управление web-продуктами \n и защита от DDoS'
        : 'Web и мультимедиа',
    textEng: 'Web products management and DDoS protection',
  },
  {
    image: cart,
    imageDark: cartDark,
    text:
      window.innerWidth > 1300
        ? 'Электронная коммерция \n и диджитал-маркетинг'
        : 'Электронная коммерция',
    textEng: 'E-commerce and digital marketing',
  },
  {
    image: graphic,
    imageDark: graphicDark,
    text: 'Инвестиционный \n маркетинг',
    textEng: 'Investment marketing',
  },
  {
    image: info,
    imageDark: infoDark,
    text: 'Выставочные \n стенды',
    textEng: 'Exhibition stands',
  },
  {
    image: fintech,
    imageDark: fintechDark,
    text: 'Fintech',
    textEng: 'Fintech',
  },
  {
    image: safety,
    imageDark: safetyDark,
    text: 'Безопасность',
    textEng: 'IT security',
  },
  {
    image: blockchain,
    imageDark: blockchainDark,
    text: 'Blockchain',
    textEng: 'Blockchain',
  },
];

const Service = ({ isLight, lang }) => {
  return (
    <section className='service-section'>
      <div className='service-section_title-wrapper'>
        <h2 className='service-section_title section-title'>
          {lang === 'ru' ? 'Наши услуги:' : 'What we do:'}
        </h2>
      </div>
      <ul className='service-section_service-list'>
        {service.map((item) => {
          return (
            <li
              className='service-section_service-item service-item'
              key={service.indexOf(item)}
            >
              <div
                className={
                  isLight
                    ? 'service-item_image-wrapper'
                    : 'service-item_image-wrapper--dark'
                }
              >
                <img
                  className='service-item_image'
                  src={isLight ? item.image : item.imageDark}
                  alt={lang === 'ru' ? item.text : item.textEng}
                />
              </div>
              <div className='service-item_text-wrapper'>
                <span className='service-item_text'>
                  {lang === 'ru' ? item.text : item.textEng}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default Service;
